import axios from "axios";

const DOMAIN_NAME = process.env.DOMAIN_NAME || "lost-angel.ru"

interface IGetSomething<T> {
    context: object,
    meta: Meta,
    rows: Array<T>
}

export interface IProductFolder {
    meta: Meta,
    id: string,
    accoundId: string,
    owner: object,
    shared: boolean,
    updated: string,
    name: string,
    externalCode: string,
    archived: boolean,
    pathName: string,
    useParentVat: boolean
}

interface Meta {
    href: string;
    metadataHref?: string;
    type: string;
    mediaType: string;
    uuidHref?: string;
    size?: number;
    limit?: number;
    offset?: number;
}

interface Owner {
    meta: Meta;
}

interface Group {
    meta: Meta;
}

interface ProductFolder {
    meta: Meta;
}

interface UOM {
    meta: Meta;
}

interface Currency {
    meta: Meta;
}

interface PriceType {
    meta: Meta;
    id: string;
    name: string;
    externalCode: string;
}

interface SalePrice {
    value: number;
    currency: Currency;
    priceType: PriceType;
}

interface BuyPrice {
    value: number;
    currency: Currency;
}

interface Barcode {
    ean13: string;
}

export interface IProduct {
    meta: Meta;
    id: string;
    accountId: string;
    owner: Owner;
    shared: boolean;
    group: Group;
    updated: string;
    name: string;
    code: string;
    externalCode: string;
    archived: boolean;
    pathName: string;
    productFolder: ProductFolder;
    useParentVat: boolean;
    uom: UOM;
    images: Images;
    minPrice: BuyPrice;
    salePrices: SalePrice[];
    buyPrice: BuyPrice;
    barcodes: Barcode[];
    paymentItemType: string;
    discountProhibited: boolean;
    weight: number;
    volume: number;
    variantsCount: number;
    isSerialTrackable: boolean;
    trackingType: string;
    files: Meta;
    stock: number;
    reserve: number;
    inTransit: number;
    quantity: number;
}

interface ImagesMeta {
    href: string;
    type: string;
    mediaType: string;
    size: number;
    limit: number;
    offset: number;
}

interface Images {
    meta: ImagesMeta;
}

interface ImageMeta {
    href: string;
    type: string;
    mediaType: string;
    downloadHref: string;
}

interface ImageMiniature {
    href: string;
    type: string;
    mediaType: string;
    downloadHref: string;
}

interface ImageTiny {
    href: string;
    type: string;
    mediaType: string;
}

interface ImageEntity {
    meta: ImageMeta;
    title: string;
    filename: string;
    size: number;
    updated: string;
    miniature: ImageMiniature;
    tiny: ImageTiny;
}

export async function getProductFolders(): Promise<Array<IProductFolder>> {

    const result = await axios.get(`https://${DOMAIN_NAME}/api/shop/folders`)

    return result.data
}

export async function getProductsByFolder(href: string): Promise<Array<IProduct>> {

    const result = await axios.get(`https://${DOMAIN_NAME}/api/shop/products?href=${href}`)



    return result.data
}


export async function getProductImage(href: string): Promise<string> {

    const result = await axios.get(`https://${DOMAIN_NAME}/api/shop/images?href=${href}`)

    return result.data.imageLink
}

export async function getProductImageById(id: string): Promise<string> {

    const result = await axios.get(`https://${DOMAIN_NAME}/api/shop/images-by-id?id=${id}`)

    return result.data.imageLink
}

export async function getOneProduct(id = '8cf4685d-651d-11ef-0a80-0309000c8599'): Promise<IProduct> {

    const result = await axios.get(`https://${DOMAIN_NAME}/api/shop/products/${id}`)



    return result.data
}